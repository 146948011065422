
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  onIonViewDidLeave,
  toastController,
} from '@ionic/vue';
import { defineComponent, inject, ref, Ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { throttle } from 'lodash';
import MessageBox from '@/components/MessageBox.vue';
import LoadingButton from '@/components/LoadingButton.vue';

export default defineComponent({
  name: 'Login',
  components: {
    MessageBox,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
    LoadingButton,
  },
  setup() {
    const router = useRouter();
    // Email validation regex taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const usernamePattern = /^[a-zA-Z0-9#]*$/;
    const setLoggedIn: (loggedIn: boolean) => void = inject('setLoggedIn', () => undefined);
    const setUserEmail: (email: string) => void = inject('setUserEmail', () => undefined);
    const setUsername: (name: string) => void = inject('setUsername', () => undefined);
    const setAccessToken: (accessToken: string) => void = inject('setAccessToken', () => undefined);
    const setRefreshToken: (refreshToken: string) => void = inject(
      'setRefreshToken',
      () => undefined,
    );
    const setIdToken: (idToken: string) => void = inject('setIdToken', () => undefined);
    const setConfirmationNeeded: (confirmationNeeded: boolean) => void = inject(
      'setConfirmationNeeded',
      () => undefined,
    );
    const getRouteImageUri: () => Ref<string> = inject('getRouteImageUri', () => ref(''));
    const usernameText = ref('');
    const passwordText = ref('');
    const errorMsg: Ref<typeof MessageBox | null> = ref(null);
    const loginButton: Ref<typeof LoadingButton | null> = ref(null);

    onIonViewDidLeave(() => {
      errorMsg.value?.close();
    });

    const isValidUsername = (username: string): boolean => {
      return username.length >= 4 && username.length <= 20 && usernamePattern.test(username);
    };

    const isValidPassword = (password: string): boolean => {
      return password.length >= 6;
    };

    const onSubmit = throttle(async (): Promise<boolean> => {
      errorMsg.value?.close();

      usernameText.value = usernameText.value.trim();

      // Invalid credentials
      if (!isValidUsername(usernameText.value)) {
        errorMsg.value?.showMsg(
          'Username has to be between 5 to 20 characters and contains only letters, numbers, and spaces',
        );
        return false;
      }
      if (!isValidPassword(passwordText.value)) {
        errorMsg.value?.showMsg('Password has to be at least 8 characters');
        return false;
      }

      // Valid credentials
      loginButton.value?.setIsLoading(true);
      setUsername(usernameText.value);
      try {
        const response = await axios.post(
          process.env.VUE_APP_USER_ENDPOINT_URL + 'login_check',
          {
            username: usernameText.value,
            password: passwordText.value,
          },
        );

        // const { AccessToken, ExpiresIn, IdToken, Message, RefreshToken } = response.data;
        setIdToken(response.data.token);//The token from symfony jwt contains the email (used at profile)
        setAccessToken(response.data.token);
        setRefreshToken(response.data.refresh_token);
        setLoggedIn(true);

        const toast = await toastController.create({
          header: 'Logged in successfully',
          position: 'bottom',
          color: 'success',
          duration: 3000,
          buttons: [
            {
              text: 'Close',
              role: 'cancel',
            },
          ],
        });
        toast.present();

        // If the user is already creating a route before login, push to "New"
        if (getRouteImageUri().value) {
          router.push({ name: 'New' });
        } else {
          router.push({ name: 'Explore' });
        }
      } catch (error:any) {
        if(error.response?.data?.message){
          errorMsg.value?.showMsg('Error: ' + error.response.data.message);
        }
        else {
          errorMsg.value?.showMsg('Error: ' + error);
        }
        
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.data.Message === 'UserNotFoundException') {
            errorMsg.value?.showMsg('Account not found, please sign up!');
          } else if (error.response.data.Message === 'NotAuthorizedException') {
            errorMsg.value?.showMsg('Incorrect email or password');
          } else if (error.response.data.Message === 'UserNotConfirmedException') {
            setConfirmationNeeded(true);
            setUserEmail(error.response.data.Email);
            router.push({ name: 'Confirm' });
          } else {
            console.error(error.response.data);
          }
        } else if (error.request) {
          errorMsg.value?.showMsg('Invalid credentials');
        } else {
          errorMsg.value?.showMsg('Error: ' + error.message);
        }
      } finally {
        loginButton.value?.setIsLoading(false);
      }
      return true;
    }, 1000);

    const clickLoginButton = (): void => {
      document.getElementById('loginButton')?.click();
    };

    return {
      onSubmit,
      clickLoginButton,
      usernameText,
      passwordText,
      errorMsg,
      loginButton,
    };
  },
});
